<template>
    <div class="modal_wrap dp_flex flex_justify_center align_center">
        <div class="vocal_modal_mobile dp_flex flex_column">
            <img
                class="vocal_modal_mobile_close"
                @click="$emit('close-vocalmodal')"
                src="/media/img/space/close.svg"
                alt="close"
            />
            <div class="vocal_modal_body">
                <h4 class="vocal_modal_title">{{ generobject.genrename }} 보컬 유무를 선택해주세요.</h4>
                <ul class="dp_flex flex_space_between vocal_modal_btngroup">
                    <li>
                        <button class="vocal_modal_selectbtn" :class="{ active: selectValue === 0 }" @click="onClickGenre(0)">
                            보컬 있음
                        </button>
                    </li>
                    <li>
                        <button class="vocal_modal_selectbtn" :class="{ active: selectValue === 1 }" @click="onClickGenre(1)">
                            보컬 없음
                        </button>
                    </li>
                    <li>
                        <button class="vocal_modal_selectbtn" :class="{ active: selectValue === 2 }" @click="onClickGenre(2)">
                            상관 없음
                        </button>
                    </li>
                </ul>
            </div>
            <div class="vocal_modal_footer">
                <button class="vocal_modal_confirmbtn" :disabled="selectValue === -1" @click="onClickConfirm">적용</button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'VocalModalM',
  emits: ['close-vocalmodal', 'confirm-vocal'],
  props: {
    generobject: {
      type: Object,
      required: false,
      default: () => {
        return {
          genrename: '재즈',
          genrevalue: 5,
          selectvalue: -1
        };
      }
    }
  },
  data () {
    return {
      selectValue: -1
    };
  },
  created () {
    this.selectValue = this.generobject.selectvalue;
  },

  mounted () {},

  methods: {
    onClickGenre (val) {
      if (val === this.selectValue) {
        this.selectValue = -1;
      } else {
        this.selectValue = val;
      }
    },
    onClickConfirm () {
      this.$emit('confirm-vocal', {
        genrevalue: this.generobject.genrevalue,
        selectVocalValue: this.selectValue
      });
    }
  }
};
</script>
<style scoped src="@/assets/css/common/modal.css"></style>
<style scoped src="@/assets/css/curation/vocalmodalmobile.css"></style>
